import api from '@/plugins/axios.js'

export function saveStoryblokDomain(domain) {
  return api.post('/storyblok-domains/save', domain)
}

export function updateStoryblokDomain(domain) {
  return api.put('/storyblok-domains/update', domain)
}

export function findAllStoryblokDomains() {
  return api.get('/storyblok-domains/all')
}

export function deleteStoryblokDomain(id) {
  return api.delete('/storyblok-domains/delete', { params: { id } })
}

export function validateStoryblokDomainName(id, name) {
  return api.get('/storyblok-domains/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
